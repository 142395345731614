var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.imageDialog),callback:function ($$v) {_vm.imageDialog=$$v},expression:"imageDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"}),_c('ImagesGallery',{attrs:{"images":_vm.images,"parent-id":_vm.damageTypeId,"file-type":"element_damage_photo","edit":false},on:{"update":function($event){return _vm.$emit('update')}}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.imageDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])],1)],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-card',{staticClass:"pa-2 d-flex justify-space-between full-width"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"mr-4"},[_c('u',[_vm._v(_vm._s(_vm.$t("inspection")))])]),_vm._v(" "+_vm._s(_vm.$t("inspection-type"))+": "+_vm._s(_vm.currentInspection.inspection_type + " | ")+" "+_vm._s(_vm.$t("inspection-name"))+": "+_vm._s(_vm.currentInspection.inspector_name + " | ")+" "+_vm._s(_vm.$t("inspection-date"))+": "+_vm._s(_vm.currentInspection.inspection_date)+" ")]),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","color":"blue","to":{
          path: _vm.detailsPath,
        }}},[_vm._v(_vm._s(_vm.$t("details")))])],1)]),_c('v-card',{staticClass:"pa-2"},[_c('v-row',{staticClass:"d-flex align-center",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('h3',[_c('u',[_vm._v(_vm._s(_vm.$t("spans"))+":")])]),_vm._l((_vm.currentInspection.InspectionSpans),function(span){return _c('v-btn',{key:span.id,staticClass:"pa-2 ml-4 my-1",attrs:{"color":_vm.selectedSpanNumber === span.span_number ? 'green' : 'grey',"small":"","rounded":""},on:{"click":function($event){return _vm.changeSpan(span.span_number)}}},[_vm._v(_vm._s(span.span_number))])})],2)]),_c('v-col',{staticClass:"text-right"},[_c('div',[_c('v-btn',{attrs:{"color":"indigo","small":"","rounded":""},on:{"click":_vm.addInspectionSpan}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"to":{
              name: 'bridge-inspection-details',
              params: { id: _vm.$route.params.id, inspectionId: _vm.$route.params.inspectionId },
            },"small":"","outlined":"","color":"blue","dark":""}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"indigo","dark":""},on:{"click":_vm.saveElementDamages}},[_vm._v(_vm._s(_vm.$t("save")))])],1)])],1)],1)],1),_c('v-card',[_c('v-row',{attrs:{"dense":""}},[_c('v-toolbar',{attrs:{"color":"green","dark":"","flat":""}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"red"}}),_vm._l((_vm.components),function(component){return _c('v-tab',{key:component.code},[_vm._v(" "+_vm._s(component[_vm.sysCodeLanguage])+" ")])})],2)],1)],1),_c('v-tabs-items',{staticClass:"mt-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.components),function(component){return _c('v-tab-item',{key:component.code},[_c('div',{staticClass:"d-flex align-center flex-column"},[_c('v-card',{staticClass:"ma-2 pa-2"},_vm._l((component.elements),function(element){return _c('table',{key:element.id},[_c('tr',[_c('th',{staticStyle:{"width":"800px"}},[_vm._v(" "+_vm._s(element[_vm.sysCodeLanguage])+" ")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("A")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("B")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("C")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("D")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("E")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t('Total')))])]),_c('tr',[_c('v-select',{staticClass:"mt-4 mx-2",attrs:{"label":_vm.$t('Material type'),"dense":"","items":element.materials,"item-text":_vm.sysCodeLanguage,"item-value":"code"},model:{value:(element.selectedMaterialCode),callback:function ($$v) {_vm.$set(element, "selectedMaterialCode", $$v)},expression:"element.selectedMaterialCode"}})],1),_vm._l((element.damages[
                  element.selectedMaterialCode
                  ]),function(damage_type,idx){return _c('tr',{key:damage_type.id},[_c('td',{staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"mx-2 d-flex "},[_c('span',{class:{ 'strike': _vm.isEmptyDamage(element.element_damages[element.selectedMaterialCode][idx]) }},[_vm._v(" "+_vm._s(damage_type.damage_type_syscode[_vm.sysCodeLanguage])+" ")]),_c('span',{staticClass:"mx-2 d-flex flex-1 justify-space-between"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.showImages(
                          element.element_damages[element.selectedMaterialCode][idx]
                        )}}},[_vm._v(" mdi-note-text-outline ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","medium":""},on:{"click":function($event){return _vm.setToDelete(element, idx)}}},[_vm._v(" mdi-delete ")])],1)])]),_c('td',[_c('v-text-field',{attrs:{"type":"number","disabled":!damage_type.a_rating,"dense":""},model:{value:(element.element_damages[element.selectedMaterialCode][
                    idx
                  ].damage_grade_a
                    ),callback:function ($$v) {_vm.$set(element.element_damages[element.selectedMaterialCode][
                    idx
                  ], "damage_grade_a", $$v)},expression:"element.element_damages[element.selectedMaterialCode][\n                    idx\n                  ].damage_grade_a\n                    "}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","disabled":!damage_type.b_rating,"dense":""},model:{value:(element.element_damages[element.selectedMaterialCode][
                    idx
                  ].damage_grade_b
                    ),callback:function ($$v) {_vm.$set(element.element_damages[element.selectedMaterialCode][
                    idx
                  ], "damage_grade_b", $$v)},expression:"element.element_damages[element.selectedMaterialCode][\n                    idx\n                  ].damage_grade_b\n                    "}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","disabled":!damage_type.c_rating,"dense":""},model:{value:(element.element_damages[element.selectedMaterialCode][
                    idx
                  ].damage_grade_c
                    ),callback:function ($$v) {_vm.$set(element.element_damages[element.selectedMaterialCode][
                    idx
                  ], "damage_grade_c", $$v)},expression:"element.element_damages[element.selectedMaterialCode][\n                    idx\n                  ].damage_grade_c\n                    "}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","disabled":!damage_type.d_rating,"dense":""},model:{value:(element.element_damages[element.selectedMaterialCode][
                    idx
                  ].damage_grade_d
                    ),callback:function ($$v) {_vm.$set(element.element_damages[element.selectedMaterialCode][
                    idx
                  ], "damage_grade_d", $$v)},expression:"element.element_damages[element.selectedMaterialCode][\n                    idx\n                  ].damage_grade_d\n                    "}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","disabled":!damage_type.e_rating,"dense":""},model:{value:(element.element_damages[element.selectedMaterialCode][
                    idx
                  ].damage_grade_e
                    ),callback:function ($$v) {_vm.$set(element.element_damages[element.selectedMaterialCode][
                    idx
                  ], "damage_grade_e", $$v)},expression:"element.element_damages[element.selectedMaterialCode][\n                    idx\n                  ].damage_grade_e\n                    "}})],1),_c('td',[_vm._v(" "+_vm._s(Number( element.element_damages[element.selectedMaterialCode][ idx ].damage_grade_a ) + Number( element.element_damages[element.selectedMaterialCode][ idx ].damage_grade_b ) + Number( element.element_damages[element.selectedMaterialCode][ idx ].damage_grade_c ) + Number( element.element_damages[element.selectedMaterialCode][ idx ].damage_grade_d ) + Number( element.element_damages[element.selectedMaterialCode][ idx ].damage_grade_e ))+" ")])])})],2)}),0)],1)])}),1)],1),(_vm.$store.getters.get_access('module_bridge_management', 3))?_c('v-card',{staticClass:"mt-16 mb-4 pa-2 red"},[_c('v-card-title',{staticClass:"justify-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('delete-span'))+" ")])]),_c('v-card-text',{staticClass:"text-center"},[(_vm.$store.getters.get_access('module_bridge_management', 3))?_c('v-btn',{staticClass:"ma-2 black--text ",attrs:{"small":"","outlined":"","color":"black"},on:{"click":_vm.toggleSpanDeletionModal}},[_vm._v(" "+_vm._s(_vm.$t("delete-span"))+" ")]):_vm._e()],1)],1):_vm._e(),_c('AreYouSureModal',{attrs:{"resource":"span","dialog":_vm.deleteDialog},on:{"toggle":_vm.toggleSpanDeletionModal,"deleted":_vm.deletedSpan}}),_c('success-error-modal',{attrs:{"dialog":_vm.dialog,"success":_vm.success,"errorData":_vm.errorData},on:{"toggleModal":_vm.toggleModal}}),_c('v-snackbar',{attrs:{"timeout":3000,"bottom":"","dismissable":"","right":"","color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-icon',{staticClass:"pl-6",on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close-circle-outline")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }