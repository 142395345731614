<template>
  <v-container fluid>
    <v-dialog v-model="imageDialog" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> </v-card-title>

        <ImagesGallery :images="images" :parent-id="damageTypeId" file-type="element_damage_photo"
          @update="$emit('update')" :edit="false"/>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="imageDialog = false">
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex flex-column">
      <v-card class="pa-2 d-flex justify-space-between full-width">
        <div class="d-flex">
          <h3 class="mr-4"><u>{{ $t("inspection") }}</u></h3>
          {{ $t("inspection-type") }}:
          {{ currentInspection.inspection_type + " | " }}
          {{ $t("inspection-name") }}:
          {{ currentInspection.inspector_name + " | " }}
          {{ $t("inspection-date") }}:
          {{ currentInspection.inspection_date }}
        </div>
        <div>
          <v-btn class="ma-2" small outlined color="blue" :to="{
            path: detailsPath,
          }">{{ $t("details") }}</v-btn>
        </div>
      </v-card>
      <!-- span -->
      <v-card class="pa-2">
        <v-row dense class="d-flex align-center">
          <v-col class="text-left">
            <div class="d-flex align-center flex-wrap">
              <h3><u>{{ $t("spans") }}:</u></h3>
              <v-btn v-for="span in currentInspection.InspectionSpans" :key="span.id" class="pa-2 ml-4 my-1" :color="selectedSpanNumber === span.span_number ? 'green' : 'grey'
                " small rounded @click="changeSpan(span.span_number)">{{ span.span_number }}</v-btn>
            </div>
          </v-col>
          <v-col class="text-right">
            <div>

              <v-btn color="indigo" small rounded @click="addInspectionSpan"><v-icon dark> mdi-plus </v-icon></v-btn>
              <v-btn :to="{
                name: 'bridge-inspection-details',
                params: { id: $route.params.id, inspectionId: $route.params.inspectionId },
              }" small outlined color="blue" class="ml-4" dark>{{ $t("cancel") }}</v-btn>
              <v-btn @click="saveElementDamages" color="indigo" class="ml-4" dark>{{ $t("save") }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- components and elements -->
    <v-card>
      <v-row dense>
        <v-toolbar color="green" dark flat>
          <v-tabs v-model="tab">
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab v-for="component in components" :key="component.code">
              {{ component[sysCodeLanguage] }}

            </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-row>
      <v-tabs-items v-model="tab" class="mt-4">
        <v-tab-item v-for="component in components" :key="component.code">
          <div class="d-flex align-center flex-column">
            <v-card class="ma-2 pa-2">
              <table v-for="element in component.elements" :key="element.id">
                <tr>
                  <th style="width: 800px">
                    {{ element[sysCodeLanguage] }}
                  </th>
                  <th style="text-align: center">A</th>
                  <th style="text-align: center">B</th>
                  <th style="text-align: center">C</th>
                  <th style="text-align: center">D</th>
                  <th style="text-align: center">E</th>
                  <th style="text-align: center">{{ $t('Total') }}</th>
                </tr>
                <tr>
                  <v-select :label="$t('Material type')" v-model="element.selectedMaterialCode" class="mt-4 mx-2" dense
                    :items="element.materials" :item-text="sysCodeLanguage" item-value="code">
                  </v-select>
                </tr>
                <tr v-for="(damage_type, idx) in element.damages[
                    element.selectedMaterialCode
                    ]" :key="damage_type.id">
                  <td style="text-align: left">
                    <div class="mx-2 d-flex ">
                      <span
                        :class="{ 'strike': isEmptyDamage(element.element_damages[element.selectedMaterialCode][idx]) }">
                        {{ damage_type.damage_type_syscode[sysCodeLanguage] }}
                      </span>
                      <span class="mx-2 d-flex flex-1 justify-space-between">
                        <v-icon color="blue" medium class="mr-2" @click="
                          showImages(
                            element.element_damages[element.selectedMaterialCode][idx]
                          )
                          ">
                          mdi-note-text-outline
                        </v-icon>
                        <v-icon color="blue" medium class="mr-2" @click="
                          setToDelete(element, idx)
                          ">
                          mdi-delete
                        </v-icon>

                      </span>
                    </div>
                  </td>
                  <td>
                    <v-text-field type="number" :disabled="!damage_type.a_rating" dense v-model="element.element_damages[element.selectedMaterialCode][
                      idx
                    ].damage_grade_a
                      "></v-text-field>
                  </td>
                  <td>
                    <v-text-field type="number" :disabled="!damage_type.b_rating" dense v-model="element.element_damages[element.selectedMaterialCode][
                      idx
                    ].damage_grade_b
                      "></v-text-field>
                  </td>
                  <td>
                    <v-text-field type="number" :disabled="!damage_type.c_rating" dense v-model="element.element_damages[element.selectedMaterialCode][
                      idx
                    ].damage_grade_c
                      "></v-text-field>
                  </td>
                  <td>
                    <v-text-field type="number" :disabled="!damage_type.d_rating" dense v-model="element.element_damages[element.selectedMaterialCode][
                      idx
                    ].damage_grade_d
                      "></v-text-field>
                  </td>
                  <td>
                    <v-text-field type="number" :disabled="!damage_type.e_rating" dense v-model="element.element_damages[element.selectedMaterialCode][
                      idx
                    ].damage_grade_e
                      "></v-text-field>
                  </td>
                  <td>
                    {{
                      Number(
                        element.element_damages[element.selectedMaterialCode][
                          idx
                        ].damage_grade_a
                      ) +
                      Number(
                        element.element_damages[element.selectedMaterialCode][
                          idx
                        ].damage_grade_b
                      ) +
                      Number(
                        element.element_damages[element.selectedMaterialCode][
                          idx
                        ].damage_grade_c
                      ) +
                      Number(
                        element.element_damages[element.selectedMaterialCode][
                          idx
                        ].damage_grade_d
                      ) +
                      Number(
                        element.element_damages[element.selectedMaterialCode][
                          idx
                        ].damage_grade_e
                      )
                    }}
                  </td>
                </tr>
              </table>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card class="mt-16 mb-4 pa-2 red" v-if="$store.getters.get_access('module_bridge_management', 3)">
      <v-card-title class="justify-center">
        <h3>
          {{ $t('delete-span') }}
        </h3>
      </v-card-title>
      <v-card-text class="text-center">
        <v-btn v-if="$store.getters.get_access('module_bridge_management', 3)" class="ma-2 black--text " small outlined
          color="black" @click="toggleSpanDeletionModal">
          {{ $t("delete-span") }}
        </v-btn>
      </v-card-text>
    </v-card>
    <AreYouSureModal resource="span" :dialog="deleteDialog" @toggle="toggleSpanDeletionModal" @deleted="deletedSpan" />
    <success-error-modal :dialog="dialog" :success="success" :errorData="errorData"
      @toggleModal="toggleModal"></success-error-modal>
    <v-snackbar v-model="snackbar" :timeout="3000" bottom dismissable right color="success">
      {{ snackbarText }}
      <v-icon @click="snackbar = false" class="pl-6">mdi-close-circle-outline</v-icon>
    </v-snackbar>
  </v-container>
</template>


<script>
import InspectionService from "@/services/InspectionService";
import SuccessErrorModal from "@/components/main/SuccessErrorModal.vue";
import ImagesGallery from "@/components/main/ImagesGallery.vue";
import AreYouSureModal from '@/components/main/AreYouSureModal.vue';

export default {
  name: "BridgeInspectionDetailsEdit",
  components: {
    SuccessErrorModal,
    ImagesGallery,
    AreYouSureModal,
  },
  props: ["currentInspection"],
  data() {
    return {
      tab: null,
      inspection: {},
      inspectionSysCodes: [],
      selectedSpanNumber: 1,
      rawSysInspectionDamages: [],
      errorData: "",
      success: false,
      dialog: false,
      snackbar: false,
      snackbarText: "",
      imageDialog: false,
      damageTypeId: null,
      deleteDialog: false,
      images: [],
    };
  },
  computed: {
    detailsPath() {
      return `/bridge-management/${this.$route.params.id}/inspection/details/${this.currentInspection.id}`;
    },
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    selectedSpanIndex() {
      if (!this.currentInspection.InspectionSpans) {
        return -1;
      }
      return this.currentInspection.InspectionSpans.findIndex(
        (x) => x.span_number === this.selectedSpanNumber
      );
    },
    components() {
      if (
        !this.currentInspection.InspectionSpans ||
        !this.currentInspection.InspectionSpans[this.selectedSpanIndex]
      ) {
        return this.inspectionSysCodes;
      }

      return this.inspectionSysCodes.map((component) => ({
        ...component,
        elements: component.elements.map((element) => {
          let selectedMaterialCode = element.selectedMaterialCode;
          const firstMatchingDamage = this.currentInspection.InspectionSpans[
                  this.selectedSpanIndex
                ].ElementDamages.find(
                  (d) =>
                    d.component === component.code &&
                    d.element === element.code
                );

          if(firstMatchingDamage) {
            selectedMaterialCode = firstMatchingDamage.element_material
          }


          element.materials.forEach((material) => {
            element.element_damages[material.code] = element.element_damages[
              material.code
            ].map((elementDamage) => {
              let damage = {
                ...elementDamage,
              };
              if (
                this.selectedSpanIndex > -1 &&
                this.currentInspection.InspectionSpans[this.selectedSpanIndex]
                  .ElementDamages
              ) {
                const existingDamage = this.currentInspection.InspectionSpans[
                  this.selectedSpanIndex
                ].ElementDamages.find(
                  (d) =>
                    d.component === component.code &&
                    d.element === element.code &&
                    d.element_material === material.code &&
                    d.damage_type === elementDamage.damage_type
                );
                
                damage = {
                  ...elementDamage,
                  id: existingDamage
                    ? existingDamage.id
                    : `${elementDamage.inspection_span_id}x${element.code}x${elementDamage.damage_type}`,
                  ElementDamagePhotos: existingDamage
                    ? existingDamage.ElementDamagePhotos
                    : [],
                  damage_grade_a: existingDamage
                    ? existingDamage.damage_grade_a
                    : null,
                  damage_grade_b: existingDamage
                    ? existingDamage.damage_grade_b
                    : null,
                  damage_grade_c: existingDamage
                    ? existingDamage.damage_grade_c
                    : null,
                  damage_grade_d: existingDamage
                    ? existingDamage.damage_grade_d
                    : null,
                  damage_grade_e: existingDamage
                    ? existingDamage.damage_grade_e
                    : null,
                  inspection_span_id:
                    this.currentInspection.InspectionSpans[
                      this.selectedSpanIndex
                    ].id,
                };
              }
              return damage;
            });
          });
          return {
            ...element,
            selectedMaterialCode,
          };
        }),
      }));
    },
  },
  watch: {
    currentInspection: {
      handler(inspection) {
        this.inspection = { ...inspection };
        this.fetchSyscodes();
      },
      immediate: true,
    },
    selectedSpanIndex: {
      handler() {
        this.createInspectionTree();
      },
      immediate: true,
    },
  },
  methods: {
    deletedSpan() {
      this.deleteLastInspectionSpan();
    },
    toggleSpanDeletionModal() {
      this.deleteDialog = !this.deleteDialog;
    },
    isEmptyDamage(damage, idx) {
      return damage.damage_grade_a === null
        && damage.damage_grade_b === null
        && damage.damage_grade_c === null
        && damage.damage_grade_d === null
        && damage.damage_grade_e === null
    },
    setToDelete(element, idx) {
      element.element_damages[element.selectedMaterialCode][idx].damage_grade_a = null;
      element.element_damages[element.selectedMaterialCode][idx].damage_grade_b = null;
      element.element_damages[element.selectedMaterialCode][idx].damage_grade_c = null;
      element.element_damages[element.selectedMaterialCode][idx].damage_grade_d = null;
      element.element_damages[element.selectedMaterialCode][idx].damage_grade_e = null;
    },
    async fetchSyscodes() {
      this.rawSysInspectionDamages = (
        await InspectionService.getSysInspectionDamage(
          this.inspection.inspection_type
        )
      ).data;
      this.createInspectionTree();
    },
    async createInspectionTree() {
      if (this.selectedSpanIndex === -1) {
        return;
      }
      const componentTypesSet = new Set();
      const componentTypesList = [];
      this.rawSysInspectionDamages.forEach((sysCode) => {
        if (!componentTypesSet.has(sysCode.component)) {
          componentTypesList.push({
            code: sysCode.component,
            description_eng: sysCode.SysComponent.description_eng,
            description_lao: sysCode.SysComponent.description_lao,
          });
          componentTypesSet.add(sysCode.component);
        }
      });

      const componentElementTypesList = componentTypesList.map((component) => {
        const elementTypesSet = new Set();
        const elementTypes = [];

        this.rawSysInspectionDamages.forEach((sysCode) => {
          if (
            sysCode.component === component.code &&
            !elementTypesSet.has(sysCode.element)
          ) {
            elementTypes.push({
              code: sysCode.element,
              description_eng: sysCode.SysElement.description_eng,
              description_lao: sysCode.SysElement.description_lao,
              materials: [],
              materialSet: new Set(),
              element_damages: {},
              damages: {},
              selectedMaterialCode: "",
            });
            elementTypesSet.add(sysCode.element);
          }
        });
        return {
          ...component,
          elements: elementTypes,
        };
      });

      this.rawSysInspectionDamages.forEach((syscode) => {
        const componentIdx = componentElementTypesList
          .map((component) => component.code)
          .indexOf(syscode.component);
        const elementIdx = componentElementTypesList[componentIdx].elements
          .map((element) => element.code)
          .indexOf(syscode.element);

        if (
          !componentElementTypesList[componentIdx].elements[
            elementIdx
          ].materialSet.has(syscode.element_material)
        ) {
          componentElementTypesList[componentIdx].elements[
            elementIdx
          ].selectedMaterialCode = syscode.element_material_syscode.code;
          componentElementTypesList[componentIdx].elements[
            elementIdx
          ].materials.push(syscode.element_material_syscode);
          componentElementTypesList[componentIdx].elements[
            elementIdx
          ].materialSet.add(syscode.element_material);
          componentElementTypesList[componentIdx].elements[
            elementIdx
          ].element_damages[syscode.element_material] = [];
          componentElementTypesList[componentIdx].elements[elementIdx].damages[
            syscode.element_material
          ] = [];
        }

        componentElementTypesList[componentIdx].elements[
          elementIdx
        ].element_damages[syscode.element_material].push({
          damage_grade_a: null,
          damage_grade_b: null,
          damage_grade_c: null,
          damage_grade_d: null,
          damage_grade_e: null,
          damage_type: syscode.damage_type,
          element: syscode.element,
          element_material: syscode.element_material,
          component: syscode.component,
          inspection_span_id:
            this.currentInspection.InspectionSpans[this.selectedSpanIndex].id,
        });

        componentElementTypesList[componentIdx].elements[elementIdx].damages[
          syscode.element_material
        ].push(syscode);
      });

      this.inspectionSysCodes = componentElementTypesList;
    },
    async deleteLastInspectionSpan() {
      await InspectionService.deleteInspectionSpan(
        this.currentInspection.InspectionSpans[
          this.currentInspection.InspectionSpans.length - 1
        ].id
      );
      this.selectedSpanNumber = this.selectedSpanNumber - 1;
      this.$emit("update");
    },
    async addInspectionSpan() {
      let span_number = 1;
      if (this.currentInspection.InspectionSpans && this.currentInspection.InspectionSpans.length) {
        span_number =
          (Math.max.apply(
            Math,
            this.currentInspection.InspectionSpans.map(
              (span) => span.span_number
            )
          ) ?? 0) + 1;
      }

      await InspectionService.addInspectionSpan({
        inspection_id: this.currentInspection.id,
        span_number,
      });
      this.selectedSpanNumber = span_number;
      this.$emit("update");
    },
    async saveElementDamages() {
      const elementsToSave = [];
      const elementsToDelete = [];
      try {
        this.components.forEach((component) => {
          component.elements.forEach((element) => {
            const materials = Object.keys(element.element_damages);
            materials.forEach(material => {
              if (material === element.selectedMaterialCode) {
                element.element_damages[material].forEach(
                  (damage) => {
                    let sum = parseInt(damage.damage_grade_a || 0)
                      + parseInt(damage.damage_grade_b || 0)
                      + parseInt(damage.damage_grade_c || 0)
                      + parseInt(damage.damage_grade_d || 0)
                      + parseInt(damage.damage_grade_e || 0);
                    if (sum === 100) {
                      elementsToSave.push(damage);
                    } else if (this.isEmptyDamage(damage)) {
                      elementsToDelete.push(damage.id);
                    } else {
                      throw new Error(`Sum of values in row must be 100 or 0. For ${damage.component}, ${damage.element} the value found is ${sum}`);
                    }
                  }
                );
              }
              else {
                // delete damages for non-selected materials
                element.element_damages[material].forEach(damage => {
                  elementsToDelete.push(damage.id);
                })
              }
            })

          });
        });
        await InspectionService.deleteElementDamages(elementsToDelete);
        await InspectionService.saveElementDamages(elementsToSave);
        this.snackbarText = "Changes in span No " + this.selectedSpanNumber.toString() + " successfully saved !";
        this.snackbar = true;
        this.success = true;
      } catch (error) {
        this.errorData = error.message;
        this.success = false;
        this.dialog = true;

      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = false;
      }
    },
    async changeSpan(spanNumber) {
      await this.saveElementDamages();
      if (this.success) {
        this.selectedSpanNumber = spanNumber;
      }
    },




    showImages(element_damage) {
      this.damageTypeId = element_damage.id;
      this.images = element_damage.ElementDamagePhotos;
      this.imageDialog = true;
    },
    getImgUrl: (img) => {
      return `https://bms.mpwt.link/media/inspection/${img.url}`;
    },
  },
};
</script>

<style scoped>
table {
  background-color: #94c6ee;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid rgb(227, 233, 233);
  max-width: 1200px;
  padding: 2px;
  margin-left: 2px;
}

td,
th {
  width: 100px;
  border: 1px solid rgb(188, 194, 194);
  background-color: rgba(227, 233, 233);
  text-align: center;
  padding: 2px;
}

.full-width {
  width: 100%;
}

.strike {
  text-decoration: line-through
}
</style>